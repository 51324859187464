import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import TextoTitulo from '../components/TextoTitulo'
import { graphql } from 'gatsby';
import BGImage from '../components/Background'
export const query = graphql`
  query {
    image: file(relativePath: { eq: "nosotros1.jpg" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 1500, maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Nosotros = ({ data }) => {
    const bkgImage = data.image.sharp;
    return (

        <Layout>
            <Seo title="Domotica Peru, IOT en Lima" siteUrl="https://madtronix-peru.com/nosotros/" />

            <BGImage ImagenFluid={bkgImage.fluid} ClaseBG="backgroundC"   >

            <div className="servicios" style={{ paddingBottom: "22rem" }}>

<div style={{ borderRadius: "5%", maxWidth: "38rem", backgroundColor: "#fff", opacity: "0.9", marginTop: "2rem" }} >


    <div style={{ paddingTop: "1rem" }} >
        <TextoTitulo titulo="¿QUIENES SOMOS?" color="#000" />
        <div style={{ padding: "2rem" }}>
            <p className="contacto-parrafo">



                Somos una empresa de transformación en Perú:
                
                Estamos comprometidos en crear un cambio positivo en el entorno a partir de proyectos de innovación y tecnología,
                
                enfocados en proyectos que signifiquen para ustedes y nosotros grandes desafíos y que además nuestro trabajo tenga un
                
                impacto relevante para nuestros clientes.
                
                
                
</p>
        </div>


    </div>
</div>


</div>

            </BGImage>


 
        </Layout>

    );
};

export default Nosotros;